import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ApplicantInfoService } from './applicant-info.service';
import { SharedService } from '../shared/shared.service';
import { TrackEventService } from '../shared/track-event/track-event-shared.service';
import { TranslationService } from '../shared/translation/translation.service';
import { combineLatest } from 'rxjs';
import { ApplicantInfoConfig } from './applicant-info-config';
import { addPlayer } from '@angular/core/src/render3/players';
import { ApplicantionConstants } from '../app-config';
import { environment } from './../../environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocalStorageService } from 'projects/fadv/web-common/src/public-api';
import { v4 as uuidv4 } from 'uuid';

declare var $: any;
declare var window: any;

@Component({
  selector: 'app-applicant-info',
  templateUrl: './applicant-info.component.html',
  styleUrls: ['./applicant-info.component.scss']
})
export class ApplicantInfoComponent implements OnInit {
  public registerForm: FormGroup;
  public consentHTML: any;
  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private applicantInfoService: ApplicantInfoService,
              private activatedRoute: ActivatedRoute,
              public trackEventService: TrackEventService,
              private ts: TranslationService,
              private sharedService: SharedService,
              private deviceService: DeviceDetectorService,
              private localStorage: LocalStorageService) { }

  public customizedText: string;
  public customizedHeader: string;
  public isSMSPrefOn = false;
  public isOptional: boolean;
  public formDisplay = false;
  public websiteUrlId: string;
  public waitingMsg: string;
  // tslint:disable-next-line:ban-types
  private trackObj: Object;
  private startTime: any;
  private endTime: any;
  private timeDiff: any;
  public formData: any;
  public codeErrorMsg = false;
  public countrycodelist: any;
  public enableNextButton = false;
  public isNumberList  = true;
  public isEmailError = false;
  public emailErrorMessage = '';
  public secondText = '';
  public customerName = ' ';
  public isInvalidEmailID = false;
  public isValidPhone = true;
  public pipeTriggerNum;
  private kioskType: string;
  private custId: string;
  private custTag: string;
  private source: string;
  private disableCoApplicants = false;

  applicantSteps: any;
  currentSlide: any;
  currentSlideWithDirection: any;
  sliderIndex: number;
  currentIteration: number;
  private candidateObjArr : any[] = [];
  isMultiple: boolean = false;
  isRightId: boolean = false;
  isAssessment: boolean = false;
  candidateDetailsEntered: boolean = false;

  public consentEnabled: boolean = true;
  private waitMessage = "Please wait as we initiate your profile, this could take some time.";

  ngOnInit() {
    this.customerName = ' ';
    this.startTime =  new Date().getTime();
    this.applicantSteps = ApplicantInfoConfig.getSteps();
    this.sliderIndex = 0;
    this.currentIteration = 0;
    this.consentEnabled = environment.consent_enabled;

    // this.ts.changeLanguage('en');
    // this.sharedService.setCurrentParentInstance(this);
    // this.pipeTriggerNum = this.sharedService.getPipeTriggerNumber();
    const urlParams = combineLatest(this.activatedRoute.params,
        this.activatedRoute.queryParams, (p, q) => ({...p, ...q}));
    urlParams.subscribe(routeP => {
          this.getConfig(routeP.kioskType, routeP.cust_id, routeP.cust_tag, routeP.language_cd);
        });
  }

  setCandidateType(obj){
    if (this.isRightId) {
      this.candidateDetailsEntered = true;
    }
    
    if (this.isRightId && obj['applicant_type'] == ApplicantionConstants.APPLICANT.toString()) {
      this.waitingMsg = this.ts.translate('WAITING_MESSAGE');
      $('#plsWaitOnIAgreeModal').modal('show');
      let email =  obj['email'];
      
      this.applicantInfoService.getInviteApplicant(this.kioskType, this.custId, this.custTag, email, this.source).subscribe(data => {
        if (data.result == undefined || data.result.toLowerCase() != 'verified'){
           window.location.href = data.applicant_url;
           $('#plsWaitOnIAgreeModal').modal('hide');
        } else {
          this.sharedService.setErrorCodeAndMsg('VERIFIED_PASSED', 'Verified and passed.');
          this.router.navigate(['error']);
          $('#plsWaitOnIAgreeModal').modal('hide');
          return;
        }
      }, error => {
        $('#plsWaitOnIAgreeModal').modal('hide');
        this.processCandidates(obj);
      });
    } else {
      this.processCandidates(obj);
    }
  }

  processCandidates(obj){
    if(this.isMultiple){
      this.candidateObjArr[this.currentIteration] = obj;
      if (obj['applicant_type'] == ApplicantionConstants.APPLICANT.toString()) {
        if (this.consentEnabled){
          this.sliderIndex +=1;
        } else {
          this.candidateObjArr[this.currentIteration]['consent'] = 'ACCEPTED',
          this.candidateObjArr[this.currentIteration]['consent_date'] = new Date().getTime()
          this.sliderIndex +=2;
        }
      } else {
        this.sliderIndex +=2;
      }
      let direction = "_right"
      this.setSlider(this.sliderIndex, this.currentIteration);
      this.currentSlideWithDirection = this.currentSlide + direction;
      this.sharedService.navigationtopMobile();
    } else {
      this.formData = this.prepareFormPayload(obj);
      this.submitData();
    }
  }

  setAdditionalCandidate(obj) {
    console.log("Set Additional Candidate ---", obj);
    if (obj['has_candidate'] === 'true') {
      this.currentIteration += 1;
      this.addApplicantForm();
      this.sliderIndex = this.applicantSteps.indexOf('candidate');
      this.setSlider(this.sliderIndex, this.currentIteration);
      let direction = "_right"
      this.currentSlideWithDirection = this.currentSlide + direction;
      this.sharedService.navigationtopMobile();
    } else {
      console.log("setAdditionalCandidate  candidateObjArr ", this.candidateObjArr);
      this.prepareBulkFormPayload(this.candidateObjArr);
      this.submitData();
    }
  }

  acceptConsent(obj) {
    console.log(obj.consent);
    this.candidateObjArr[this.currentIteration]['consent'] = obj.consent;
    this.candidateObjArr[this.currentIteration]['consent_date'] = obj.consent_date;
    this.candidateObjArr[this.currentIteration]['consent_signature'] = obj.consent_signature;
    if (this.disableCoApplicants){
      let obj: Object = {};
      let indexDetail: Object = {};
      indexDetail['row'] = 0;
      obj['has_candidate'] = 'false';
      obj['indexDetail'] = indexDetail;
      this.setAdditionalCandidate(obj);
      this.sharedService.navigationtopMobile();
      return;
    }
    this.sliderIndex +=1;
    let direction = "_right"
    this.setSlider(this.sliderIndex, this.currentIteration);
    this.currentSlideWithDirection = this.currentSlide + direction;
    this.sharedService.navigationtopMobile();
  }

  submitData() {
    this.waitingMsg = this.ts.translate('WAITING_MESSAGE');
    $('#plsWaitOnIAgreeModal').modal('show');
      // obj.kiosk_id = this.websiteUrlId;
    this.applicantInfoService.submitApplicantInfo(this.kioskType, this.custId, this.custTag, this.customerName, this.source, this.formData).subscribe(data => {
      this.processTrackEvent('kiosk details submitted');
      console.log(data);
      let redirectUrl = '';
      if (this.isRightId) {
        redirectUrl = data['kiosk-applicant-list'][0].applicant_url;
      } else {
        redirectUrl = data.applicant_url;
      }
      window.location.href = redirectUrl;
      $('#plsWaitOnIAgreeModal').modal('hide');
    }, error => {
      $('#plsWaitOnIAgreeModal').modal('hide');
      this.sharedService.setErrorCodeAndMsg('INVITE_FAILURE', error.errorMessage);
      this.processTrackEvent('kiosk details failed');
      this.router.navigate(['error']);
    });
  }

  public initApplicantForm() {
    this.registerForm = this.formBuilder.group({
      registerSubForm: this.formBuilder.array([
        this.initAppForm(),
      ])
    });
    this.formDisplay = true;
  }

  initAppForm() {
    let frmCtrls = {
      first_name: ['', Validators.required],
      middle_name: [''],
      last_name: ['', Validators.required],
      email: ['', Validators.required]
    }
    if (this.isSMSPrefOn) {
      if (this.isOptional) {
        frmCtrls['phone'] = [''];
        frmCtrls['countryCode'] = [''];
      } else {
        frmCtrls['phone'] = ['', Validators.required];
        frmCtrls['countryCode'] = ['', Validators.required];
      }
    }
    if(this.isMultiple && this.currentIteration > 0){
      frmCtrls['applicant_type'] = ['', Validators.required];
    }
    return this.formBuilder.group(frmCtrls);
  }

  addApplicantForm(){
    const control = <FormArray>this.registerForm.controls['registerSubForm'];
    control.push(this.initAppForm());
  }

  public getConfig(kioskType, custId, custTag, languageCd?) {
    this.kioskType = kioskType;
    this.custId = custId;
    this.custTag = custTag;
    this.formDisplay = false;

    this.isRightId = (this.kioskType !== undefined && this.kioskType.toLowerCase() === 'rightid');
    this.isAssessment = (this.kioskType !== undefined && this.kioskType.toLowerCase() === 'assessment');
    if (!custId || !custTag) {
      this.router.navigate(['error']);
    }
    if(this.isRightId && this.deviceService.isDesktop()){
      this.waitingMsg = this.waitMessage;
      $('#plsWaitOnIAgreeModal').modal('show');
    }
    let that = this;
    this.applicantInfoService.getConfig(kioskType, custId, custTag, languageCd).subscribe(data => {
      console.log("Inside getConfig api call result.");
      const languagesList = data.languages ? data.languages.split(',') : [];
      const defaultLanguage = data.defaultLanguage ? data.defaultLanguage : null;
      this.sharedService.setRouteInfo(custId, custTag, defaultLanguage);
      this.sharedService.setUserLanguages(defaultLanguage, languagesList);
      // this.customizedText = data.welcomeMsg;
      this.customerName = data.customer_name;
      const customStyle = data.custom_styles;
      this.source = data.source;
      $('#customStyles').html(customStyle);

      if (that.isRightId) {
        this.customizedText = this.ts.translate('WELCOME_TEXT_RIGHTID', this.customerName);
      } else if(that.isAssessment){
        this.customizedText = this.ts.translate('WELCOME_TEXT_ASSESSMENT', this.customerName);
      } else {
        this.customizedText = this.ts.translate('WELCOME_TEXT_1', this.customerName);
      }
      // tslint:disable-next-line:max-line-length
      this.secondText = this.ts.translate('INFORMATION_TEXT_1');

      if (data.welcomeMsg) {
        this.secondText = data.welcomeMsg;
      }

      window.SupportBus.emit('fadv::support::boot',
      {
          initializeUrl: environment.salesForceInitializeUrl,
          chatSettings: environment.salesForceChatSettings,
          initBaseUrl: environment.salesForceChatBaseUrl,
          embeddedServiceMinJS: environment.salesForceChatEmbeddedServiceMinJS,
          chatPosition: environment.salesForceChatPosition
      });
      
      this.localStorage.setItem('visiter_id', uuidv4());
      this.trackEventService.updateTrackEvent('kiosk started', { stage_name: 'kiosk info', action_value: 'page load' });

      // this.customizedHeader = data.description;
      this.websiteUrlId = data.websiteUrlId;
      this.isSMSPrefOn = (data.phoneNumberPreferenceValue === '3'
      || data.phoneNumberPreferenceValue === '2'
      || that.isRightId) ? true : false;
      if (this.isSMSPrefOn) {
        this.isOptional = data.phoneNumberPreferenceValue === '2' || that.isRightId ? true : false;
      }
      if (that.isRightId) {
        let signatureFont = data.SIGNATURE_FONT;
        this.localStorage.setItem("SIGNATURE_FONT", signatureFont);
      }
      //this.createFormControls();
      this.initApplicantForm();
      let direction = "_right"
      this.setSlider(this.sliderIndex, this.currentIteration);
      this.currentSlideWithDirection = this.currentSlide + direction;
      if (this.isRightId) {
        //TODO
        this.isMultiple = true;
        this.custTag = data.tag; //Changing encrypted value to the actual value.
        this.custId = data.urlPath;
      }
      if(!this.isMultiple){
        let index = this.applicantSteps.indexOf("candidate_confirm");
        if (index != -1) {
          this.applicantSteps.splice(index, 1);
        }
      }

      let userLangCd = this.sharedService.validateLanguage(languageCd);
      console.log("RightId: "+this.isRightId+ " accountId: "+this.custId+" accountTag: "+this.custTag+" inviteSource: "+this.source+ " langCd "+userLangCd);
      if(this.isRightId){
        this.initRightIdFlow("RIGHTID",this.custId, this.custTag, this.source,userLangCd );
      }

    }, error => {
      this.sharedService.setErrorCodeAndMsg(error.errorCode, error.errorMessage);
      switch (error.errorCode) {
        case 'BAD_REQUEST':
        this.router.navigate(['error']);
        break;
        case 'LINK_EXPIRED':
        this.router.navigate(['error']);
      }
    });

  }

  public initRightIdFlow(inviteType, accountId, accountTag, inviteSource,languageCd){
    console.log("In initRightIdFlow method");
    let customMessage: String =  'ERROR_MSG_DESKTOP_TRUE';
    let countryCd = 'US';
    let that = this;
    this.localStorage.setItem('account_id', accountId);
    this.localStorage.setItem('tag_id', accountTag);
    this.applicantInfoService.getAccountInfo(inviteType, accountId, accountTag, inviteSource,countryCd,languageCd).subscribe(data => {

      //1. Below code can be used to check other preferences.
       if(data['preferences'] != undefined &&
         data['preferences'].length > 0){
           let  preferenceList = data['preferences'];
           for(var preference of preferenceList){
              if(preference['name'] == 'DISABLE_ADD_CO_APPLICANT' && preference['value'] == 'YES'){
                 that.disableCoApplicants = true;
                 continue;
              }
              if(preference['name'] == 'REDIRECT_URL' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined){
                that.localStorage.setItem('redirect_url', preference['value']);
                continue;
            }
             if (preference['name'] == ApplicantInfoConfig.FONT_BASED_SIGNATURE_PREF && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined) {
               that.localStorage.setItem('use_font_based_signature', preference['value']);
               continue;
             }
           }
         }

      // Read account specific messages.
        if(data['messages'] != undefined &&
        data['messages'].length > 0){
          let messages = data['messages'];
          if(messages != undefined && messages.length > 0){
            for(var message of messages){
              // if value is not availble for the key then we will not consider this message.
              if (message['value'] === undefined || message['value'] === '' || message['value'] == null){
                continue;
              }
              switch(message['key']){
                case 'CONTINUE_ON_MOBILE_MSG':
                  customMessage = message['value'];
                  break;
                case 'DECLINE_ESIGNATURE_CONFIRM_TEXT':
                  that.localStorage.setItem('decline_esignature_confirm_text', message['value']);
                  break;
                case 'DECLINE_ESIGNATURE_TEXT':
                  that.localStorage.setItem('decline_esignature_text', message['value']);
                  break;
                }
            }
          }
        }
        // Check if the application is opened on desktop. If yes, display message and terminiate the flow.
        console.log("Is it Desktop? "+ this.deviceService.isDesktop());
        $('#plsWaitOnIAgreeModal').modal('hide');
        if(this.deviceService.isDesktop()){
          if (customMessage !== 'ERROR_MSG_DESKTOP_TRUE') {
            let redirectURL = this.localStorage.getItem('redirect_url');
            if (redirectURL != undefined && redirectURL !== '' ){
                let url = new URL(redirectURL);
                var search_params = url.searchParams;
                search_params.set('action', 'NO_MOBILE_ACCESS');
                search_params.set('accountid', accountId);
                search_params.set('tagid', accountTag);
                let new_url = url.toString();
                customMessage = customMessage.replace('{0}', new_url)
            }
          }
          this.trackEventService.updateTrackEvent('kiosk rightid on desktop', {stage_name: 'kiosk info', action_value: 'page load'});
          this.sharedService.setErrorCodeAndMsg('DESKTOP_TRUE', customMessage);
          this.router.navigate(['/error']);
          return;
        }
        this.requestConsent("RIGHTID",this.custId, this.custTag, 'RIGHTID', languageCd);
    },error => {
        this.sharedService.setErrorCodeAndMsg(error.errorCode, error.errorMessage);
        console.log("initRightIdFlow errorCode and errorMessage", error.errorCode, error.errorMessage);
        this.router.navigate(['error']);
    });

  }

  public requestConsent(inviteType, accountId, accountTag, inviteSource, languageCd){
    let countryCd = 'US';
    let that = this;
    this.applicantInfoService.getAccountForm(inviteType, accountId, accountTag, inviteSource, countryCd, languageCd).subscribe(data => {
      if(data['messages'] != undefined &&
      data['messages'].length > 0){
        let messages = data['messages'];
        if(messages != undefined && messages.length > 0){
          for(var message of messages){
              if(message['key'] == 'CONSENT'){
                that.consentHTML = message['value'];
              }
          }
        }
      }
      if ( that.consentHTML == undefined ||  that.consentHTML == null ||  that.consentHTML == '') {
        this.sharedService.setErrorCodeAndMsg('CONSENT_NOTFOUND', 'Consent not found.');
        this.router.navigate(['error']);
      }
      if (this.consentHTML != undefined && this.consentHTML != null) {
        this.consentHTML = this.consentHTML.replace(/{customerName}/gi, this.customerName);
      }
    },error => {
      this.sharedService.setErrorCodeAndMsg('CONSENT_NOTFOUND', 'Consent not found.');
      this.router.navigate(['error']);
    });
  }

  public processTrackEvent(eventName) {
    this.endTime =  new Date().getTime();
    this.timeDiff = (this.endTime - this.startTime) / 1000;
    this.timeDiff = Math.floor(this.timeDiff);

    this.trackObj = {
      stage_name: 'kiosk info',
      action_value: 'page load',
      time_spent: this.timeDiff
    };
    // Update track event/log audit
    this.trackEventService.updateTrackEvent(eventName, this.trackObj);
  }

  public prepareBulkFormPayload(objArray) {
    console.log("objArray :", objArray);
    if (objArray) {
      let len = objArray.length;
      console.log("prepareBulkFormPayload obj len ", len);
      let formArrData = [];
      for (let i = 0; i < len; i++) {
        let obj = objArray[i];
        let payload = this.prepareFormPayload(obj);
        formArrData.push(payload);
      }
      this.formData = formArrData;
      console.log("prepareBulkFormPayload this.formData ", this.formData);
    }
  }

  public prepareFormPayload(obj) {
    let payload = {
      kiosk_id : this.websiteUrlId,
      first_name : obj.first_name,
      middle_name : obj.middle_name,
      last_name : obj.last_name,
      email : obj.email,
      consent : obj.consent,
      consent_date : obj.consent_date,
      consent_signature : obj.consent_signature
    };
    if (this.isSMSPrefOn) {
      if (!this.isOptional || (this.isOptional && obj.countryCode && obj.phone)) {
        if (this.isRightId) {
          payload['countryCode']= obj.countryCode;
          payload['phone']= obj.phone;
        } else {
          const phoneNumber =  obj.countryCode + ' ' + obj.phone;
          payload['phone']= phoneNumber;
        }

      }
    }
    if (this.sharedService.getLanguageCd()) {
      payload['language'] = this.sharedService.getLanguageCd();
    }
    if (this.isRightId) {
      payload['applicant_type'] = obj.applicant_type;
    }
    return payload;
  }

  private setSlider(index, i?, j?): void {
    let seprator: string = '_';
    this.currentSlide = this.applicantSteps[index];
    if (i > -1) {
      this.currentSlide += `${seprator}${i}`;
    }
    if (j > -1) {
      this.currentSlide += `${seprator}${j}`;
    }
  }

  // changeLanguage() {
  //   this.ts.changeLanguage('fr');
  //   this.sharedService.setPipeTriggerNumber(2);
  //   console.log(this.pipeTriggerNum);
  // }
}
